.imageScaling {
    height: 75px;
    width: 75px;
}

.content-card {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    background-color:  #141f38;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 4px #0000001a;
    flex-grow: 1;
    align-items: flex-start;
}

.content-card ul {
    text-align: left;
}

.image {
    position: absolute;
    top: 10px;
    right: 10px;
}

@media (max-width: 768px) {
    .imageScaling {
        height: 75px;
        width: 75px;
    }
    
}

.date {
    align-self: flex-end;
}

