

@media (min-width: 768px) {
    .vertical-navbar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 200px;
        background-color: #0f172a;
        color: white;
        padding: 40px;
        font-size: 20px;
        
        /* Add any other styling you need */
    }    
    .vertical-navbar img {
        max-width: 100%;
        height: auto;
        border-radius: 50%;
    }
}


@media (max-width: 768px) {
    .vertical-navbar {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0f172a;
        color: white;
        padding: 10px 20px;
        font-size: 20px;
        text-align: center;
        height: 100vh; /* Set the height to 100% of viewport height */
        width: 100%; /* Set the width to 100% */
    }

    .vertical-navbar img {
        max-width: 75%;
        height: auto;
        border-radius: 50%;
    }
}
  
  .vertical-navbar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .vertical-navbar li {
    margin-bottom: 10px;
  }
  
  .vertical-navbar a {
    text-decoration: none;
    color: white;
  }

a:hover{
  font-size: 25px;
}

.toggle-button {
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  background-color: #393956;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-button:hover {
  background-color: #2c2c40;
}


