.blog-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:  #141f38;
    max-width: 600px;
    height: 600px;
    border-radius: 20px;

}

.blog-title {
    justify-self: center;
}

.blog-description {
    justify-self: center;
    padding-left: 20px;
    padding-right: 20px;
}

.blog-image {
    max-width: 100%; /* Resize image to fit wrapper width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 20px 20px 0 0; /* Apply rounded corners to the top */
}