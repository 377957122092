.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
  }
  
  @media (max-width: 768px) {
    .content-container {
      padding: 0 10px; /* Adjust padding for smaller screens */
    }
  }
  
  body {
    background-color: #0b1120;
    color: white;
    margin: 0; /* Reset default margin */
    overflow-x: hidden; /* Hide horizontal scrollbar if needed */
  }
  
  .contentBox {
    /* Define your content styles */
    /* background-col or: white; */
    display: flex;
    justify-content: center;
    padding-left: 20px;
    flex-wrap: wrap; /* Allow cards to wrap onto new rows */

  }
  
  
  
  @media (max-width: 1200px) {
    .contentBox {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  
  .project-div {
    display: flex;
    background-color: white;
  }
  
  .sectionHeader {
    align-items: baseline;
  }
  
  
  @media (max-width: 768px) {
    .main-content {
      padding: 20px; /* Add appropriate padding */
      /* Other styling properties */
    }
  }
  
  @media (min-width: 768px) {
    .main-content {
      margin-left: 250px;
      padding: 20px;
    }
  }
  
  .hobbies-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:  #141f38;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
  }